import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import Utils from "@shared/helpers/utils";
import { cRetosResponse } from "@shared/Interface/IRetosResponse";
import { cSolicitudGetResponse } from "@shared/Interface/ISolicitudGetResponse";
import { UsuarioGetResponse } from "@shared/Interface/IUsuarioGetResponse";
import { RetosService } from "@shared/service/Retos/retos.service";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom, tap } from "rxjs";
import { SolicitudesService } from "../../shared/service/Solicitudes/solicitudes.service";
import { IUsuariosAcademiaResponse } from "@shared/Interface/IUsuariosAcademiaResponse";
import {
  CUnidadFormativa,
  ICampus,
  IEscuela,
  IModalidad,
  IPeriodo,
  IPeriodoSemanal,
} from "@shared/Interface/ICatalogos.interface";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import Swal from "sweetalert2";
import { ContactosService } from "@shared/service/Contactos/contactos.service";
import { IContacto } from "@shared/Interface/IContacto.interface";
import { formatDate } from "@angular/common";
import { ISocioFormadorResponse } from "@shared/Interface/ISocioFormadorResponse";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  ISociosSolicitudComentarios,
  ISolicitudComentarios,
} from "@shared/Interface/ISolicitudSocioComentarios";
declare const window: any;
@Component({
  selector: "app-solicitud",
  templateUrl: "./solicitud.component.html",
  styleUrls: ["./solicitud.component.css"],
})
export class SolicitudComponent implements OnInit {
  periodosData: IPeriodo[];
  campusData: ICampus[];
  sociosData: ISocioFormadorResponse[] = [];
  modalidadData: IModalidad[] = [
    {
      idModalidad: 1,
      Modalidad: "Presencial",
    },
    {
      idModalidad: 2,
      Modalidad: "Virtual",
    },
    {
      idModalidad: 3,
      Modalidad: "Hibrida",
    },
  ];

  idReto: string | null;
  typeSolicitud: string | null;
  idAcademico: string | null;

  profesorSolicita: any;
  retoInformacion: any;
  socioFormador: any;
  idSolicitud: any;
  solicitudData: any;
  clavePerfil: string;
  lbl_Empresa: string;

  titleSolicitud = {
    title: "Solicitud de académico que busca un Socio Formador",
  };

  // Secciones de cuadro
  lbl_alcance = "Multi-Alcance";
  lbl_semestre = "Multi-Semestre";
  lbl_semana_numero = "1";
  lbl_semana = "1 Semana";
  lbl_idioma_numero = "Es";
  lbl_idioma = "Multi-Idioma";

  // seccion detalle
  lbl_det_alcance = "";
  lbl_det_semestre = "";
  lbl_det_idioma = "";

  // sección busqueda
  lbl_busqueda_acerca_sf = "-";
  lbl_busqueda_areas = "-";
  lbl_busqueda_alcance = "-";
  lbl_busqueda_nombre = "-";
  lbl_busqueda_contexto = "-";
  lbl_busqueda_objetivos = "-";

  lbl_det_programaAcademico = "";

  lbl_sf_sectores = "Sector:<br /> ";
  lbl_sf_tamanos = "Tamaño:<br /> ";
  lbl_sf_industria = "Industria:<br /> ";
  lbl_sf_presencias = "Presencia:<br /> ";
  lbl_sf_descripcion = "";

  lbl_periodosSemestrales: string = "";
  lbl_periodosSemanales: string = "";
  lbl_modalidad: string = "";

  lbl_programasAcademicos: string = "";
  lbl_idiomas: string = "";

  lbl_periodo: string = "";
  lbl_periodosSemanales_sol: string = "";

  muestraAlerta: boolean = false;
  muestraBotones: boolean = false;

  lbl_listado_campus = "";
  lbl_campus_coordinador = "";
  lbl_sf_descripcionSolicitud = "";

  profesoresxCampus: IUsuariosAcademiaResponse[];
  contactoSeleccionado: number;

  lbl_status_registrado: string = "";
  formModal: any;
  formModalProponerSF: any;

  proponerSFForm: FormGroup;

  busquedaPorBancodeSocios: boolean = false;
  lblMedioBusqueda = '';

  constructor(
    private route: ActivatedRoute,
    private retosService: RetosService,
    private socioFormadorService: SociosFormadoresService,
    private usuariosService: UsuariosService,
    private solicitudesService: SolicitudesService,
    private toastr: ToastrService,
    private routr: Router,
    private catalogoService: CatalogosService,
    private contactoService: ContactosService,
    private fb: FormBuilder
  ) {
    this.catalogoService
      .getPeriodo()
      .pipe(tap((response) => (this.periodosData = response)))
      .subscribe();

    this.catalogoService
      .getCampus()
      .pipe(tap((resultado: ICampus[]) => (this.campusData = resultado)))
      .subscribe();

    this.getEstatus();
  }

  ngOnInit(): void {
    this.retoInformacion = new cRetosResponse();
    this.solicitudData = new cSolicitudGetResponse();
    this.profesorSolicita = new UsuarioGetResponse();
    this.clavePerfil = this.usuariosService.getCurrentUserData().clavePerfil;
    this.idReto = this.route.snapshot.paramMap.get("idReto");
    this.idAcademico = this.route.snapshot.paramMap.get("idAcademico");
    this.typeSolicitud = this.route.snapshot.paramMap.get("type");
    this.idSolicitud = this.route.snapshot.paramMap.get("idSolicitud");
    this.getRetoInfo(this.idReto);
    this.idAcademico && this.getAcademicoInfo(this.idAcademico);
    this.getSolicitud(this.idSolicitud);

    this.formModal = new window.bootstrap.Modal(
      document.getElementById("idModalResolver")
    );

    this.formModalProponerSF = new window.bootstrap.Modal(
      document.getElementById("idModalResolverAcademico")
    );

    this.getSociosFormadores();
    this.initFormProponerSF();
    this.initFormInfoReto(null);
    this.initFormVincula();
    this.getEscuela();
    this.getPeriodosSemanales();
    this.initFormEstatusSolForm();
    this.initFormProponerSFAcademico();

    if ("socio" === this.clavePerfil) {
      this.titleSolicitud.title =
        "Académico que quiere trabajar con mi organización";
    }

    if("academia" === this.clavePerfil){
      this.titleSolicitud.title =
      "Mis búsquedas de Socios Formadores"
    }
  }

  async getSociosFormadores() {
    this.socioFormadorService
      .getAllSociosFormadores("", 0, 0, "", 0, "", 0, false,false)
      .pipe(
        tap((response) => {
          this.sociosData = response;
        })
      )
      .subscribe();
  }

  getRetoInfo(idReto: any) {
    this.retosService
      .getRetoMaestroDetail(idReto)
      .pipe(
        tap((response) => {
          if (response) {
            this.retoInformacion = response;

            this.lbl_periodosSemestrales = Utils.ListToString(this.retoInformacion.periodosSemestrales,'periodoSemestral');
            this.lbl_periodosSemanales = Utils.ListToString(this.retoInformacion.periodosSemanales,'periodoSemanal');
            this.lbl_idiomas = Utils.ListToString(this.retoInformacion.idiomas,'idioma');
            this.lbl_programasAcademicos = Utils.ListToString(this.retoInformacion.programasAcademicos,'programaAcademico');
            this.lbl_sf_sectores += Utils.ListToString(this.retoInformacion.sectores, 'sector');
            this.lbl_sf_tamanos += Utils.ListToString(this.retoInformacion.tamanos, 'tamano');
            this.lbl_sf_industria += Utils.ListToString(this.retoInformacion.industrias, 'industria');
            this.lbl_sf_presencias += Utils.ListToString(this.retoInformacion.presencias, 'presencia');

            this.lbl_sf_descripcion +=
              this.retoInformacion.palabrasClave ||
              "Sin descripción de Socio Formador";

            this.lbl_det_alcance = Utils.ListToString(this.retoInformacion.alcances, 'alcance');

            if (!this.lbl_det_alcance.includes(",")) {
              this.lbl_alcance = this.lbl_det_alcance;
            }
            this.lbl_alcance =
              this.lbl_alcance.length == 0 ? "-" : this.lbl_alcance;

            this.lbl_det_semestre = Utils.ListToString(this.retoInformacion.semestres, 'semestre');

            if (!this.lbl_det_semestre.includes(",")) {
              this.lbl_semestre = `${this.lbl_det_semestre} Semestre`;
            }
            this.lbl_semestre =
              this.lbl_semestre.length == 0 ? "-" : this.lbl_semestre;

            this.lbl_semana = this.retoInformacion.semanas || "";
            if (this.lbl_semana.length > 0) {
              this.lbl_semana_numero = this.lbl_semana.replace(" semanas", "");
            }
            this.lbl_semana_numero =
              this.lbl_semana_numero.length == 0 ? "-" : this.lbl_semana_numero;

            this.lbl_det_idioma = Utils.ListToString(this.retoInformacion.idiomas, 'idioma');


            if (this.retoInformacion.idiomas.length > 1) {
              this.lbl_idioma_numero = "*";
            } else {
              this.lbl_idioma_numero = this.lbl_det_idioma.slice(0, 2);
            }
            this.lbl_det_idioma =
              this.lbl_det_idioma.length == 0 ? "-" : this.lbl_det_idioma;

            this.getContactosxSF(this.solicitudData.idSocioFormador);
          }
        })
      )
      .subscribe();
  }

  contactosData: IContacto[];
  async getContactosxSF(idSocioFormador) {
    if (idSocioFormador == 0) {
      return;
    }
    try {
      this.contactosData = (
        await lastValueFrom(this.contactoService.getContactos(idSocioFormador))
      ).map((x) => {
        return { ...x, nombreCompleto: x.nombre + " " + x.apellidos };
      });
      this.lblProfesorAsignado = "";
    } catch (err) {
      Utils.processErrorResponse(err);
    }
  }

  lblProfesorAsignado = "No asignado";

  getSocioFormadorInfo(idSocioFormador: any) {
    if (idSocioFormador == 0) {
      return;
    }
    this.socioFormadorService
      .getSociosFormadoresById(idSocioFormador)
      .pipe(
        tap((response) => {
          this.socioFormador = response;
          this.socioFormador.urlLogo = this.getLogoSF(
            this.socioFormador.urlLogo
          );
          this.lbl_Empresa = Utils.cutLongTextByXChars(
            this.socioFormador.empresa,
            50
          );
        })
      )
      .subscribe();
  }

  getAcademicoInfo(idUsuario: any) {
    this.usuariosService
      .getUsuarioById(idUsuario)
      .pipe(tap((response) => (this.profesorSolicita = response)))
      .subscribe();
  }

  lbl_busqueda_academico_informacion: string;
  async getSolicitud(idSolicitud: any) {
    try {
      let response = await lastValueFrom(
        this.solicitudesService.getSolicitudById(idSolicitud)
      );
      this.solicitudData = response;
      this.lbl_periodosSemanales_sol = this.solicitudData.listaPeriodosSemanales
        .map((x: IPeriodoSemanal) => x.periodoSemanal)
        .join(", ");
      this.lbl_sf_descripcionSolicitud = response.descripcionSolicitud.replace(
        "Características: ",
        ""
      );
      this.lbl_periodo =
        this.periodosData.filter((x) => x.idPeriodo == response.idPeriodo).pop()
          ?.periodo ?? "-";

      this.getSocioFormadorInfo(response.idSocioFormador);

      await this.getSolicitudComentarios();

      if (response.idTipoSolicitud == 3) {
        this.getPropuestaSF();
      }
      let resPropustaComments;
      try {
        resPropustaComments = await lastValueFrom(
          this.solicitudesService.getPropuestaSF_comentarios(
            response.idSolicitud
          )
        );
      } catch (err) {
        Utils.processErrorResponse(err);
      }

      if (resPropustaComments && !['aprobado','rechazado','aceptado'].includes(response.claveEstatus) ) {
        let ultComment = resPropustaComments[resPropustaComments.length -1 ];
        this.lbl_status_registrado = `${
          ultComment.nombreUsuario
        } ha enviado una propuesta de Socio Formador el ${formatDate(
          new Date(ultComment.fechaRegistro),
          "dd 'de' MMMM 'de' yyyy",
          "es_MX"
        )}`;
      }
      else if(['aprobado','rechazado'].includes(response.claveEstatus)){
        let nombreUsuario = this.comentariosData[0].nombreUsuario;
        this.lbl_status_registrado =
        nombreUsuario + " ha " +
        response.claveEstatus +
        " la propuesta de Socio Formador el " +
        formatDate(
          new Date(response.fechaRegistro),
          "dd 'de' MMMM 'de' yyyy",
          "es_MX"
        ) +
        ".";
      }
      else {
        this.lbl_status_registrado =
          "Esta solicitud fue " +
          Utils.getEstatusFemale(response.claveEstatus) +
          " el " +
          formatDate(
            new Date(response.fechaRegistro),
            "dd 'de' MMMM 'de' yyyy",
            "es_MX"
          ) +
          ".";
      }

      this.estatusSolForm.get("estatus")?.patchValue(response.claveEstatus);

      this.lbl_modalidad = response.modalidad ?? "-";

      if (response.idTipoSolicitud === 5 && !response.parametrosBusquedaSocio) {
        this.lblMedioBusqueda = 'Banco de Socios';
        this.busquedaPorBancodeSocios = true;
      } else {
        this.lblMedioBusqueda = "Algoritmo";
        this.busquedaPorBancodeSocios = false;

        let res = JSON.parse(response.parametrosBusquedaSocio);
        if (res) {
          this.lbl_sf_descripcionSolicitud =
            res.partnerDescription + " " + this.lbl_sf_descripcionSolicitud;


          this.lbl_busqueda_areas = res.partnerCharacteristics;
          this.lbl_busqueda_alcance = res.presence.join(",");
          this.lbl_busqueda_nombre = res.industry.join(",");
          this.lbl_busqueda_contexto = res.sector.join(",");
          this.lbl_busqueda_objetivos = res.size.join(",");
        }

        if (response.idTipoSolicitud === 5) {
          this.lbl_busqueda_academico_informacion = res.partnerDescription;
          this.lbl_busqueda_acerca_sf = res.partnerDescription;
        }
        if (response.idTipoSolicitud === 3) {
          this.lbl_busqueda_academico_informacion = this.lbl_sf_descripcionSolicitud;
          if(response.idLogBusquedaReto == 0 && response.idLogBusquedaSocio == 0){
            this.lblMedioBusqueda = "Banco de Retos";
          }
          //this.lbl_busqueda_acerca_sf = this.lbl_sf_descripcionSolicitud;
        }
      }

      this.mostrarBotones();
    } catch (error) {
      Utils.processErrorResponse(error);
    }
  }

  handleAceptar2_5() {
    this.getPropuestaSF();
    this.formModal.show();
  }

  handleAceptar2_4() {
    this.mostrarRechazo = false;
    this.mostrarReasignar = false;
    this.mostrarAceptarAcademico = true;
  }

  handleRechazar() {
    this.mostrarAceptarAcademico = false;
    this.mostrarReasignar = false;
    this.mostrarRechazo = true;

    Swal.fire({
      input: "textarea",
      inputLabel: "Motivo de rechazo",
      inputPlaceholder: "Escribe el motivo del rechazo",
      inputValidator: (value) => {
        if (!value) {
          return "Debes escribir un motivo";
        }
        else{
          return null;
        }
      },
      inputAttributes: {
        "aria-label": "Escribe el motivo del rechazo",
      },
      showCancelButton: true,
    }).then((response) => {
      if (response.isConfirmed) {
        this.motivoRechazo = response.value;
        this.enviarSolicitud();
      }
    });
  }

  handleReasignar() {
    this.mostrarAceptarAcademico = false;
    this.mostrarRechazo = false;
    this.mostrarReasignar = true;
  }

  enviarSolicitud() {
    if (this.mostrarAceptarAcademico) {
      if ((this.contactoSeleccionado ?? 0) === 0) {
        this.toastr.error("Debe seleccionar un contacto");
        return;
      }

      let idUSuario = this.usuariosService.getCurrentUserData().idUsuario;

      this.solicitudesService.postSolicitudComentario({
        idSolicitud: this.solicitudData.idSolicitud,
        claveEstatus: "aprobado",
        comentarios: "Solicitud Aprobada",
        idUsuarioRegistro: idUSuario,
      }).subscribe();


      this.solicitudesService
        .putSolicitudEstatus({
          idSolicitud: this.idSolicitud,
          claveEstatus: "aprobado",
          idUsuario: idUSuario,
          idAcademia: this.solicitudData.idAcademia,
          idUsuarioSocioFormador: this.contactoSeleccionado,
          idCampus: this.solicitudData.idCampus,
        })
        .subscribe({
          next: (resOK) => {
            this.toastr.success("Aprobada correctamente");
            window.location.href = "/dashboard";
          },
          error: (error) => {
            this.toastr.success("Hubo un error");
            window.history.back();
          },
        });
    }
    if (this.mostrarRechazo) {
      let idUSuario = this.usuariosService.getCurrentUserData().idUsuario;

        this.solicitudesService.postSolicitudComentario({
          idSolicitud: this.solicitudData.idSolicitud,
          claveEstatus: "rechazado",
          comentarios: this.motivoRechazo,
          idUsuarioRegistro: idUSuario,
        }).subscribe();


      this.solicitudesService
        .putSolicitudEstatus({
          idSolicitud: this.idSolicitud,
          claveEstatus: "rechazado",
          idUsuario: idUSuario,
          idAcademia: this.solicitudData.idAcademia,
          motivoEstatus: this.motivoRechazo,
          idCampus: this.solicitudData.idCampus,
        })
        .subscribe({
          next: (resOK) => {
            this.toastr.success("Rechazada correctamente");
            window.location.href = "/dashboard";
          },
          error: (error) => {
            this.toastr.success("Hubo un error");
            window.history.back();
          },
        });
    }

    if (this.mostrarReasignar) {
      Swal.fire({
        input: "textarea",
        inputLabel: "Motivo de reasignación",
        inputPlaceholder: "Escribe el motivo de reasignación",
        inputAttributes: {
          "aria-label": "Escribe el motivo de reasignación",
        },
        inputValidator: (value) => {
          if (!value) {
            return "Debes escribir un motivo";
          }
          else{
            return null;
          }
        },
        showCancelButton: true,
      }).then((response) => {
        if (response.isConfirmed) {
          this.motivoRechazo = response.value;

          this.solicitudesService.postSolicitudComentario({
            idSolicitud: this.solicitudData.idSolicitud,
            claveEstatus: "reasignado",
            comentarios: this.motivoRechazo,
            idUsuarioRegistro: this.usuariosService.getCurrentUserData().idUsuario,
          }).subscribe();


          this.solicitudesService
            .putSolicitudEstatus({
              idUsuario: this.usuariosService.getCurrentUserData().idUsuario,
              idSolicitud: this.idSolicitud,
              claveEstatus: "reasignado",
              idAcademia: this.solicitudData.idAcademia,
              idCampus: this.campusSeleccionado,
              motivoEstatus: this.motivoRechazo,
            })
            .subscribe({
              next: (resultOK) => {
                this.toastr.success("Solicitud reasignada con éxito.");
                this.routr.navigateByUrl("/dashboard");
              },
              error: (error) => {
                this.toastr.error("Hubo un error, intenta más tarde.");
                console.log("reasignado", error);
              },
            });
        }
      });
    }
  }

  seleccionarContacto($event) {
    this.contactoSeleccionado = $event?.target?.value ?? $event.idContacto;
  }

  seleccionarCampus($event) {
    this.campusSeleccionado = $event?.target?.value ?? $event.idCampus;
  }

  mostrarAceptarAcademico: boolean = false;
  mostrarRechazo: boolean = false;
  mostrarReasignar: boolean = false;
  motivoRechazo: string = "";
  campusSeleccionado: number;

  mostrarDetalle() {
    this.formModalProponerSF.hide();
    if(this.propuestaSF){
      localStorage.removeItem("idSocioFormador");
      localStorage.setItem("idSocioFormador", this.propuestaSF.idSocioFormador.toString());
      let url = '/socio-formador';
      this.routr.navigate([url]);
    }
  }

  mostrarBotones() {

    if (
      ["cvdp", "cvdp_admin"].includes(
        this.usuariosService.getCurrentUserData().clavePerfil
      )
    ) {
      this.muestraAlerta = true;
      this.muestraBotones = true;
    }
    if (this.usuariosService.getCurrentUserData().clavePerfil == "academia") {
      if (this.solicitudData.claveEstatus == "aprobado") {
        this.muestraAlerta = true;
        this.muestraBotones = true;
      } else {
        this.muestraAlerta = true;
        this.muestraBotones = false;
      }

      if (
        this.solicitudData.claveEstatus == "aceptado" ||
        this.solicitudData.claveEstatus == "no-aceptado"
      ) {
        this.muestraBotones = false;
        this.muestraAlerta = true;
      }
      // 'aceptado' -- 'no-aceptado'
      if (this.solicitudData.claveEstatus == "propuesta") {
        this.muestraAlerta = true;
        this.muestraBotones = true;
      }
    }

    if ("socio" === this.usuariosService.getCurrentUserData().clavePerfil) {
      this.muestraAlerta = true;
      this.muestraBotones = false;
      if ("aprobado" === this.solicitudData.claveEstatus) {
        this.muestraBotones = true;
      }
    }

  }

  initFormProponerSF() {
    this.proponerSFForm = this.fb.group({
      idSocioFormador: [""],
      nombreSocioFormador: [""],
      comentarios: ["", Validators.required],
    });
  }

  initFormEstatusSolForm() {
    this.estatusSolForm = this.fb.group({
      estatus: [],
      comentario: [],
    });
  }

  initFormProponerSFAcademico() {
    this.proponerSFAcademicoForm = this.fb.group({
      comentarios: ["", Validators.required],
    });
  }

  initFormInfoReto(infoReto: any) {
    this.infoRetoForm = this.fb.group({
      idEscuela: [infoReto?.idEscuela],
      idUnidadFormativa: [infoReto?.idUnidadFormativa],
    });
  }
  initFormVincula() {
    this.vinculaForm = this.fb.group({
      idPeriodo: [0],
      idPeriodoSemanal: [],
      idModalidad: [],
    });
  }

  async guardarPropuestaSF() {
    this.proponerSFForm.markAllAsTouched();
    let idUsuario = this.usuariosService.getCurrentUserData().idUsuario;
    if((this.proponerSFForm.get("idSocioFormador")?.value ?? 0) == 0  && this.proponerSFForm.get("nombreSocioFormador")?.value.toString().trim() == "")
    {
      this.toastr.warning("Selecciona o escribe un Socio Formador");
      return;
    }
    if (this.proponerSFForm.valid) {
      this.formModal.hide();
      try {
        if (this.proponerSFForm.get("idSocioFormador")?.value != 0) {
          try {
            await lastValueFrom(
              this.solicitudesService.postPropuestaSF({
                idSolicitud: this.solicitudData.idSolicitud,
                idSocioFormador: this.proponerSFForm.get("idSocioFormador")
                  ?.value,
                nombreSocioFormador: "",
                comentarios: this.proponerSFForm.get("comentarios")?.value,
                idUsuario: idUsuario,
              })
            );

            await this.getPropuestaSF();
          } catch (err) {
            console.log("Error", err);
          }
        } else {
          try {
            await lastValueFrom(
              this.solicitudesService.postPropuestaSF({
                idSolicitud: this.solicitudData.idSolicitud,
                idSocioFormador: 0,
                nombreSocioFormador: this.proponerSFForm.get(
                  "nombreSocioFormador"
                )?.value,
                comentarios: this.proponerSFForm.get("comentarios")?.value,
                idUsuario: idUsuario,
              })
            );
          } catch (err) {
            console.log("Error", err);
          }
        }

        await lastValueFrom(
          this.solicitudesService.putSolicitudEstatus({
            idSolicitud: this.idSolicitud,
            claveEstatus: "propuesta",
            idUsuario: idUsuario,
            idAcademia: this.solicitudData.idAcademia,
            idUsuarioSocioFormador: this.contactoSeleccionado,
            idCampus: this.solicitudData.idCampus,
          })
        );

        await lastValueFrom(
          this.solicitudesService.postSolicitudComentario({
            idSolicitud: this.solicitudData.idSolicitud,
            claveEstatus: "propuesta",
            comentarios: this.proponerSFForm.get("comentarios")?.value,
            idUsuarioRegistro: idUsuario,
          })
        );
      } catch (error) {
        console.log(error);
      }
      this.ngOnInit();
      this.toastr.success("Guardado con éxito");
    }
    else{
      this.toastr.warning("Escribe un Comentario");
    }
  }

  limpiarCampos(campo: string) {
    if (campo === "idSocioFormador")
      this.proponerSFForm.get(campo)?.setValue(0);
    if (campo === "nombreSocioFormador")
      this.proponerSFForm.get(campo)?.setValue("");
  }

  edit: boolean = false;
  async editar() {
    this.edit = true;
    this.mostrarAceptarAcademico = false;
    this.mostrarReasignar = false;
    this.infoRetoForm
      .get("idEscuela")
      ?.patchValue(this.retoInformacion.idEscuela);
    await this.getUnidadFormativa(this.retoInformacion.idEscuela);
    this.infoRetoForm
      .get("idUnidadFormativa")
      ?.patchValue(this.retoInformacion.idUnidadFormativa);

    this.vinculaForm.get("idPeriodo")?.patchValue(this.solicitudData.idPeriodo);
    this.vinculaForm
      .get("idModalidad")
      ?.patchValue(this.solicitudData.idModalidad);

    this.solicitudData.listaPeriodosSemanales.forEach((el) => {
      this.periodoSemanalChk.push(el.idPeriodoSemanal);
    });

    //this.showFormularioCancelacion = false;
  }
  async getUnidadFormativa(event) {
    try {
      let idEscuela = event.target?.value ?? event;
      let resultado = await lastValueFrom(
        this.catalogoService.getUnidadFormativa(idEscuela)
      );
      this.unidadFormativaData = resultado;
    } catch (error) {
      Swal.fire({
        icon: "info",
        text: "No hay Unidades Formativas asociados",
      });
    }
  }

  getEscuela() {
    this.catalogoService
      .getEscuelas(0)
      .pipe(
        tap((resultado: IEscuela[]) => {
          this.escuelaData = resultado;
        })
      )
      .subscribe();
  }

  async getRetoDetail(event: any) {
    try {
      const idUnidadFormativa = event;
      let res = await lastValueFrom(
        this.retosService.getRetosMaestrosxUnidadFormativa_original(
          idUnidadFormativa
        )
      );
      let resultado = res[0];
      this.getRetoInfo(resultado.idRetoMaestro);
    } catch (error) {
      Utils.processErrorResponse(error);
    }
  }

  verificaChecked(idPeriodoSemanal) {
    return this.periodoSemanalChk.includes(idPeriodoSemanal);
  }

  verificaCheckPeriodoSemanal(event) {
    let idPeriodoSemanal: number = Number(event.target.value);
    let index = this.periodoSemanalChk.findIndex((x) => x === idPeriodoSemanal);
    if (
      idPeriodoSemanal == this.PERIDO_SEMANAL_SEMESTRAL &&
      event.target.checked
    ) {
      this.periodoSemanalChk = [1, 2, 3, 4];
    } else {
      if (idPeriodoSemanal == this.PERIDO_SEMANAL_SEMESTRAL)
        this.periodoSemanalChk = [];
      else {
        if (
          this.periodoSemanalChk.findIndex(
            (x) => x == this.PERIDO_SEMANAL_SEMESTRAL
          ) > 0
        )
          this.periodoSemanalChk.splice(
            this.periodoSemanalChk.findIndex(
              (x) => x == this.PERIDO_SEMANAL_SEMESTRAL
            ),
            1
          );
      }

      if (index < 0) {
        this.periodoSemanalChk.push(idPeriodoSemanal);
      } else {
        this.periodoSemanalChk.splice(index, 1);
      }
    }
  }

  async getPeriodosSemanales() {
    try {
      this.periodoSemanalData = await lastValueFrom(
        this.catalogoService.getPeriodoSemanal()
      );
    } catch (error) {
      Utils.processErrorResponse(error);
    }
  }

  guardarEdicion() {
    let idUSuario = this.usuariosService.getCurrentUserData().idUsuario;
    let listaPeriodosSemanales = this.periodoSemanalData.filter((el) =>
      this.periodoSemanalChk.includes(el.idPeriodoSemanal)
    );

    this.solicitudesService
      .putSolicitud({
        idSolicitud: this.idSolicitud,
        claveEstatus: this.solicitudData.claveEstatus,
        idUsuario: idUSuario,
        idAcademia: this.solicitudData.idAcademia,
        idUsuarioSocioFormador: this.contactoSeleccionado,
        idCampus: this.solicitudData.idCampus,
        idPeriodo: this.vinculaForm.get("idPeriodo")?.value,
        idModalidad: this.vinculaForm.get("idModalidad")?.value,
        listaPeriodosSemanales: listaPeriodosSemanales,
        idRetoMaestro: this.retoInformacion.idRetoMaestro,
      })
      .subscribe({
        next: async (resOK) => {
          let cveEstatus = this.estatusSolForm.get("estatus")?.value;
          let comentario = this.estatusSolForm.get("comentario")?.value;
          await lastValueFrom(
            this.solicitudesService.putSolicitudEstatus({
              idSolicitud: this.idSolicitud,
              claveEstatus: cveEstatus,
              idUsuario: idUSuario,
              idAcademia: this.solicitudData.idAcademia,
              motivoEstatus: comentario,
              idCampus: this.solicitudData.idCampus,
            })
          );

          //await this.saveComment(cveEstatus, comentario);

          this.toastr.success("Guardado correctamente");
          this.edit = false;
          this.ngOnInit();
        },
        error: (error) => {
          this.toastr.success("Hubo un error");
          window.history.back();
        },
      });
  }

  cancelarEdicion() {
    this.edit = false;
  }

  async getPropuestaSF() {
    try {
      let res = await lastValueFrom(
        this.solicitudesService.getPropuestaSF_comentarios(
          this.solicitudData.idSolicitud
        )
      );

      res = res.sort(
        (x, y) =>
          y.idSolicitudSocioFormadorPropuesto -
          x.idSolicitudSocioFormadorPropuesto
      );

      this.propuestasData = res;
      this.propuestaSF = res[0];
      console.log(this.propuestaSF);

      if(this.propuestaSF.nombreSocioFormador == "")
      {
        this.propuestaSF.nombreSocioFormador = 'No asignado';
      }

      this.propuestaSF.urlLogo = this.getLogoSF(this.propuestaSF.urlLogo);
    } catch (error) {
      Utils.processErrorResponse(error);
    }
  }

  getLogoSF(urlLogo: string) {
    let imgLogo =
      urlLogo ??
      "https://mvretosstorage.blob.core.windows.net/mvpretos-app-assets/logo_sf_sin.jpg";
    /*
    20241011 - se solicita que si se muestre el logo default
    if (imgLogo == "https://mvretosstorage.blob.core.windows.net/mvpretos-app-assets/logo_sf_sin.jpg") {
      return "";
    }*/
    return imgLogo;
  }

  async verPropuesta() {
    try {
      this.verRechazoPropuestaSFComentarios = false;
      this.formModalProponerSF.show();
    } catch (error) {
      Utils.processErrorResponse(error);
    }
  }

  async aceptarPropuestaSF() {
    let idUsuario = this.usuariosService.getCurrentUserData().idUsuario;
    let comentario = this.estatusSolForm.get("comentario")?.value;

    this.formModalProponerSF.hide();

    await lastValueFrom(
      this.solicitudesService.putSolicitudEstatus({
        idSolicitud: this.idSolicitud,
        claveEstatus: "aprobado",
        idUsuario: idUsuario,
        idAcademia: this.solicitudData.idAcademia,
        motivoEstatus: comentario ?? "Propuesta Aceptada",
        idCampus: this.solicitudData.idCampus,
      })
    );

    await lastValueFrom(
      this.solicitudesService.putSolicitud({
        idSolicitud: this.idSolicitud,
        idSocioFormador: this.propuestaSF?.idSocioFormador,
        idUsuario: idUsuario,
      })
    );

    await lastValueFrom(
      this.solicitudesService.postSolicitudComentario({
        idSolicitud: this.solicitudData.idSolicitud,
        claveEstatus: "aprobado",
        comentarios: comentario ?? "Propuesta aprobada",
        idUsuarioRegistro: idUsuario,
      })
    );


    this.toastr.success("Aceptado correctamente");
    this.ngOnInit();
  }

  veRechazarPropuestaSF() {
    this.verRechazoPropuestaSFComentarios = true;
  }

  async rechazarPropuestaSF() {
    this.proponerSFAcademicoForm.markAllAsTouched();
    if (this.proponerSFAcademicoForm.valid) {

      this.formModalProponerSF.hide();

      let idUsuario = this.usuariosService.getCurrentUserData().idUsuario;
      let comentario = this.proponerSFAcademicoForm.get("comentarios")?.value;
      await lastValueFrom(
        this.solicitudesService.putSolicitudEstatus({
          idSolicitud: this.idSolicitud,
          claveEstatus: "rechazado",
          idUsuario: idUsuario,
          idAcademia: this.solicitudData.idAcademia,
          motivoEstatus: comentario,
          idCampus: this.solicitudData.idCampus,
        })
      );

      await lastValueFrom(
        this.solicitudesService.postSolicitudComentario({
          idSolicitud: this.solicitudData.idSolicitud,
          claveEstatus: "rechazado",
          comentarios: comentario,
          idUsuarioRegistro: idUsuario,
        })
      );

      this.toastr.success("Solicitud rechazada correectamente.");
      this.ngOnInit();
    }
  }

  obtenerEstatusSeguimiento(estatus: string) {
    if (estatus)
      return this.estatusData.find((x) => x.claveEstatus == estatus).estatus;
  }

  async getEstatus() {
    try {
      this.estatusData = await lastValueFrom(this.catalogoService.getEstatus());
    } catch (error) {
      Utils.processErrorResponse(error);
    }
  }

  async getSolicitudComentarios() {
    try {
      this.comentariosData = [];
      this.comentariosData = await lastValueFrom(
        this.solicitudesService.getSolicitudComentarios(
          this.solicitudData.idSolicitud
        )
      );
    } catch (error) {
      Utils.processErrorResponse(error);
    }
  }

  async saveComment(estatus: string, comentario: string) {
    try {
      let idUsuario = this.usuariosService.getCurrentUserData().idUsuario;
      let result = await lastValueFrom(
        this.solicitudesService.postSolicitudComentario({
          idSolicitud: this.solicitudData.idSolicitud,
          claveEstatus: estatus,
          comentarios: comentario,
          idUsuarioRegistro: idUsuario,
        })
      );


      await lastValueFrom(this.solicitudesService
        .putSolicitudEstatus({
          idSolicitud: this.idSolicitud,
          claveEstatus: estatus,
          idUsuario: idUsuario
        }));

    } catch (err) {
      this.toastr.error("Hubo un error al guardar el comentario");
      console.log(err);
    }
  }

  async guardarComentarios() {
    let estatus =
      this.estatusSolForm.get("estatus")?.value ??
      this.solicitudData.claveEstatus;
    let comentario = this.estatusSolForm.get("comentario")?.value;

    if (comentario.length == 0) {
      this.toastr.error("El comentario no puede estar vacio");
      return;
    }

    await this.saveComment(estatus, comentario);

    this.getSolicitudComentarios();
    this.toastr.success("Comentario guardado correctamente");
    this.estatusSolForm.get("comentario")?.patchValue("");
  }

  comentariosData: ISolicitudComentarios[] = [];
  panelOpenState = false;
  estatusData: any = [];

  verRechazoPropuestaSFComentarios: boolean = false;
  propuestaSF?: ISociosSolicitudComentarios;
  propuestasData: any;

  estatusSolForm: FormGroup;
  infoRetoForm: FormGroup;
  vinculaForm: FormGroup;
  proponerSFAcademicoForm: FormGroup;
  unidadFormativaData: CUnidadFormativa[];
  escuelaData: IEscuela[];
  periodoSemanalData: IPeriodoSemanal[];
  periodoSemanalChk: number[] = [];
  PERIDO_SEMANAL_SEMESTRAL = 4;

  enviarSolicitudActualizarEstatusSocio(respuestaSocio) {
    if ("aceptado" == respuestaSocio) {
      Swal.fire({
        text: "¿Desea aceptar esta solicitud?",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          let idUSuario = this.usuariosService.getCurrentUserData().idUsuario;

            this.solicitudesService.postSolicitudComentario({
              idSolicitud: this.solicitudData.idSolicitud,
              claveEstatus: "aceptado",
              comentarios: "Solicitud Aceptada",
              idUsuarioRegistro: idUSuario,
            }).subscribe();


          this.solicitudesService
            .putSolicitudEstatus({
              idSolicitud: this.idSolicitud,
              claveEstatus: "aceptado",
              idUsuario: idUSuario,
              idAcademia: this.solicitudData.idAcademia,
              idUsuarioSocioFormador: idUSuario,
              idCampus: this.solicitudData.idCampus,
            })
            .subscribe({
              next: (resOK) => {
                this.toastr.success("Aceptado correctamente");
                window.history.back();
              },
              error: (error) => {
                this.toastr.success("Hubo un error");
                window.history.back();
              },
            });
        }
      });
    }
    if ("rechazado" === respuestaSocio) {
      Swal.fire({
        text: "¿Desea rechazar esta solicitud?",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        input: "textarea",
        inputLabel: "Motivo de rechazo",
        inputPlaceholder: "Escribe el motivo del rechazo",
        inputValidator: (value) => {
          if (!value) {
            return "Debes escribir un motivo";
          }
          else{
            return null;
          }
        },
        inputAttributes: {
          "aria-label": "Escribe el motivo del rechazo",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          let idUSuario = this.usuariosService.getCurrentUserData().idUsuario;

          this.solicitudesService.postSolicitudComentario({
            idSolicitud: this.solicitudData.idSolicitud,
            claveEstatus: "rechazado",
            comentarios: result.value,
            idUsuarioRegistro: idUSuario,
          }).subscribe();


          this.solicitudesService
            .putSolicitudEstatus({
              idSolicitud: this.idSolicitud,
              claveEstatus: "rechazado",
              idUsuario: idUSuario,
              idAcademia: this.solicitudData.idAcademia,
              motivoEstatus: result.value,
              idCampus: this.solicitudData.idCampus,
              idUsuarioSocioFormador: idUSuario,
            })
            .subscribe({
              next: (resOK) => {
                this.toastr.success("Rechazada correctamente");
                window.history.back();
              },
              error: (error) => {
                this.toastr.success("Hubo un error");
                window.history.back();
              },
            });
        }
      });
    }
  }
}
